.toster {
    position: absolute;
    height: 50px;
    width: 286px;
    z-index: 999;
    right: -5px;
}

.hide {
    top: -100px;
    transition: 1s;
}

.show {
    top: 10px;
    transition: 1s;
}